/* eslint-disable array-callback-return */
/* eslint-disable */
import {
  GET_BOITE_PRESENTATION_FILE_LIST,
  ADD_FILE_BOITE_PRESENTATION,
  EDIT_FILE_BOITE_PRESENTATION,
  SHOW_SUCCESS_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  HIDE_ERROR_MESSAGE,
  SET_DUPLICATION_ID,
  GET_SEARCHED_FILES_BY_COLUMN,
  EDIT_JO,
  SET_FILE_STATUS,
} from "../../Const/ActionTypes";
import _ from "lodash";
import { setLoading, unsetLoading } from "./UIActions";
import { incrementCount } from "./CommunFilesAction";
import { crudService } from "../../Config/CrudService";

export const getBoitePresentationFiles = () => {
  return (dispatch) => {
    crudService
      .get(`/boite-presentations`)
      .then((res) => {
        dispatch({
          type: GET_BOITE_PRESENTATION_FILE_LIST,
          payload: res.data.reverse(),
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const globalBoitePresentationEdit = (data, fileId, sectionName) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .put(`/boite-presentations/${fileId}`, data)
      .then((res) => {
        dispatch({
          type: EDIT_FILE_BOITE_PRESENTATION,
          payload: res.data,
        });
        dispatch(
          getBoitePresentationPDF(
            "BoitePresentation",
            data,
            res.data.id,
            "boite-presentations",
            EDIT_FILE_BOITE_PRESENTATION,
            [
              {
                name: "priceRtuSection",
                path: "joLinkPdf",
                link: "joLinkText",
                listOld: "oldRtuPriceList",
              },
              {
                name: "specificInformationSection",
                path: "atuPdfLink",
                link: "atuLink",
                listOld: "oldspecificInformationSection",
              },
              {
                name: "infoJoSection",
                path: "joInfoLinkPdf",
                link: "joInfoLinkText",
                listOld: "oldinfoJOList",
              },
              {
                name: "inscriptionSSCollPriceSSSection",
                listOld: "oldInscriptionSSCollPriceSSSection",
                data: [
                  {
                    path: "joLink1Pdf",
                    link: "joLink1Text",
                    file: "joLink1File",
                  },
                  {
                    path: "joLink2Pdf",
                    link: "joLink2Text",
                    file: "joLink2File",
                  },
                  {
                    path: "joLink3Pdf",
                    link: "joLink3Text",
                    file: "joLink3File",
                  },
                  {
                    path: "joLink4Pdf",
                    link: "joLink4Text",
                    file: "joLink4File",
                  },
                  {
                    path: "joLink5Pdf",
                    link: "joLink5Text",
                    file: "joLink5File",
                  },
                  {
                    path: "ameliCipLinkPdf",
                    link: "ameliCipLinkText",
                    file: "ameliCipLinkFile",
                  },
                ],
              },
              {
                name: "t2AregistrationPriceRediationSection",
                listOld: "oldT2AregistrationPriceRediationSection",
                data: [
                  {
                    path: "ameliUCDLinkPdf",
                    link: "ameliUCDLinkText",
                    file: "ameliUCDLinkFile",
                  },
                  {
                    path: "SecondJoLinkPdf",
                    link: "SecondJoLinkText",
                    file: "SecondJoLinkFile",
                  },
                  {
                    path: "thirdJoLinkPdf",
                    link: "thirdJoLinkText",
                    file: "thirdJoLinkFile",
                  },
                  {
                    path: "joLinkPdf",
                    link: "joLinkText",
                    file: "joLinkFile",
                  },
                ],
              },
              {
                name: "retrocessionSection",
                listOld: "oldRetrocessionSection",
                data: [
                  {
                    path: "joLinkPdf",
                    link: "joLinkText",
                    file: "joLinkFile",
                  },
                  {
                    path: "ameliUcdLinkPdf",
                    link: "ameliUcdLinkText",
                    file: "ameliUcdLinkFile",
                  },
                  {
                    path: "secondJoLinkPdf",
                    link: "secondJoLinkText",
                    file: "secondJoLinkFile",
                  },
                  {
                    path: "thirdJoLinkPdf",
                    link: "thirdJoLinkText",
                    file: "thirdJoLinkFile",
                  },
                ],
              },
            ]
          )
        );
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload:
            sectionName === undefined
              ? "La fiche Boite presentation ( ID : " +
                res.data.id +
                " ) est modifiée avec succès"
              : 'Les informations de la section "  ' +
                sectionName +
                ' " sont confirmées avec succès',
          sectionAlerte: sectionName,
        });

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        let errorMsg =
          err.response === undefined
            ? "Merci de réessayer ultérieurement , une erreur s'est produite lors de modification de la fiche Boite presentation"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const changeEmailAlert = (fileId, bpAlertEmail) => {
  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .get(`/boite-presentations?id=${fileId}`)
      .then((res) => {
        const foundFile = res.data?.[0];
        if (foundFile) {
          dispatch(
            globalBoitePresentationEdit(
              {
                ...foundFile,
                communInformation: {
                  ...foundFile.communInformation,
                  bpAlertEmail,
                },
              },

              fileId
            )
          );
        } else {
          const errorMsg = `La fichie avec l'ID : ${fileId} n'a pas été trouvée`;

          dispatch({
            type: SHOW_ERROR_MESSAGE,
            payload: errorMsg,
          });
        }
      })
      .catch((err) => {
        console.error("error", err);
        redirect();
      })
      .finally(() => {
        dispatch(unsetLoading(true));
      });
  };
};

export const globalBoitePresentationSubmit = (data, duplication = false) => {
  let mutatedData = data;

  if (duplication) {
    const { specificInformationSection } = mutatedData;

    if (specificInformationSection?.cip7) {
      mutatedData = {
        ...data,
        specificInformationSection: {
          ...specificInformationSection,
          cip7: null,
        },
      };
    }
  }

  return (dispatch) => {
    dispatch(setLoading());

    crudService
      .post(`/boite-presentations`, mutatedData)
      .then((res) => {
        dispatch({
          type: ADD_FILE_BOITE_PRESENTATION,
          payload: res.data,
        });

        if (duplication) {
          dispatch({ type: SET_DUPLICATION_ID, payload: res.data.id });

          setTimeout(() => {
            dispatch({ type: SET_DUPLICATION_ID, payload: null });
          }, 2000);
        }
        dispatch(
          getBoitePresentationPDF(
            "BoitePresentation",
            data,
            res.data.id,
            "boite-presentations",
            EDIT_FILE_BOITE_PRESENTATION,
            [
              {
                name: "priceRtuSection",
                path: "joLinkPdf",
                link: "joLinkText",
                listOld: "oldRtuPriceList",
              },
              {
                name: "specificInformationSection",
                path: "atuPdfLink",
                link: "atuLink",
                listOld: "oldspecificInformationSection",
              },
              {
                name: "infoJoSection",
                path: "joInfoLinkPdf",
                link: "joInfoLinkText",
                listOld: "oldinfoJOList",
              },
              {
                name: "inscriptionSSCollPriceSSSection",
                listOld: "oldInscriptionSSCollPriceSSSection",
                data: [
                  {
                    path: "joLink1Pdf",
                    link: "joLink1Text",
                    file: "joLink1File",
                  },
                  {
                    path: "joLink2Pdf",
                    link: "joLink2Text",
                    file: "joLink2File",
                  },
                  {
                    path: "joLink3Pdf",
                    link: "joLink3Text",
                    file: "joLink3File",
                  },
                  {
                    path: "joLink4Pdf",
                    link: "joLink4Text",
                    file: "joLink4File",
                  },
                  {
                    path: "joLink5Pdf",
                    link: "joLink5Text",
                    file: "joLink5File",
                  },
                  {
                    path: "ameliCipLinkPdf",
                    link: "ameliCipLinkText",
                    file: "ameliCipLinkFile",
                  },
                ],
              },
              {
                name: "t2AregistrationPriceRediationSection",
                listOld: "oldT2AregistrationPriceRediationSection",
                data: [
                  {
                    path: "ameliUCDLinkPdf",
                    link: "ameliUCDLinkText",
                    file: "ameliUCDLinkFile",
                  },
                  {
                    path: "SecondJoLinkPdf",
                    link: "SecondJoLinkText",
                    file: "SecondJoLinkFile",
                  },
                  {
                    path: "thirdJoLinkPdf",
                    link: "thirdJoLinkText",
                    file: "thirdJoLinkFile",
                  },
                  {
                    path: "joLinkPdf",
                    link: "joLinkText",
                    file: "joLinkFile",
                  },
                ],
              },
              {
                name: "retrocessionSection",
                listOld: "oldRetrocessionSection",
                data: [
                  {
                    path: "joLinkPdf",
                    link: "joLinkText",
                    file: "joLinkFile",
                  },
                  {
                    path: "ameliUcdLinkPdf",
                    link: "ameliUcdLinkText",
                    file: "ameliUcdLinkFile",
                  },
                  {
                    path: "secondJoLinkPdf",
                    link: "secondJoLinkText",
                    file: "secondJoLinkFile",
                  },
                  {
                    path: "thirdJoLinkPdf",
                    link: "thirdJoLinkText",
                    file: "thirdJoLinkFile",
                  },
                ],
              },
            ]
          )
        );
        dispatch({
          type: SHOW_SUCCESS_MESSAGE,
          payload: duplication
            ? "La fiche Boîte présentation  est dupliquée avec succès  ( ID : " +
              res.data.id +
              " )"
            : "Une nouvelle fiche Boîte présentation est ajoutée avec succès  ( ID : " +
              res.data.id +
              " )",
        });

        dispatch(incrementCount());

        setTimeout(() => {
          dispatch({ type: HIDE_SUCCESS_MESSAGE });
        }, 4000);
      })
      .catch((err) => {
        console.log("Error: ", err);
        let errorMsg =
          err.response === undefined
            ? "Merci  de réessayer ultérieurement, une erreur s'est produite lors d'ajout d'une nouvelle fiche Boîte présentation"
            : err.response.data.error.message;

        dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};

export const getBoitePresentationPDF = (
  agencyModel,
  data,
  fileId,
  api,
  EDIT_TYPE,
  sectionName
) => {
  return (dispatch) => {
    if (!(data.id && data.id !== fileId)) {
      let enableUpload = false;
      /** if edit function  */
      if (data.id !== undefined) {
        sectionName.map((section) => {
          if (data[section.name] && data[section.listOld]) {
            let pdfToDelete = section.data
              ? _.flatten(
                  section.data.map((dataEle) =>
                    _.map(
                      _.differenceBy(
                        data[section.listOld],
                        data[section.name],
                        dataEle.path
                      ),
                      dataEle.path
                    )
                  )
                )
              : _.map(
                  _.differenceBy(
                    data[section.listOld],
                    data[section.name],
                    section.path
                  ),
                  section.path
                );
            pdfToDelete?.filter((e) => e).map((e) => {
              crudService.remove(`/blob-storage-azure`, { key: e });
              enableUpload = true;
            });
          }
        });
      }
      let formatedUrls = [];
      const formData = new FormData();
      sectionName.map((section) => {
        if (data[section.name]) {
          // check if type of section is an array
          if (
            typeof data[section.name] !== "object" ||
            Array.isArray(data[section.name])
          ) {
            data[section.name].map((element, index) => {
              if (section.data) {
                section.data.map((dataEle) => {
                  if (
                    element[dataEle.link]?.length &&
                    element[dataEle.file] === undefined &&
                    element[dataEle.path] === ""
                  ) {
                    const fileExtension = element[dataEle.link].replace(
                      /^.*\./,
                      ""
                    );
                    formatedUrls.push({
                      name: index + dataEle.path + 1 + "_link." + fileExtension,
                      path: element[dataEle.link],
                      index: section.name + dataEle.path + index,
                    });
                    enableUpload = true;
                  }
                  if (element[dataEle.file] !== undefined) {
                    const myNewFile = new File(
                      [element[dataEle.file]],
                      index +
                        dataEle.path +
                        1 +
                        "_" +
                        element[dataEle.file].name
                    );

                    formData.append(
                      section.name + dataEle.path + index,
                      myNewFile
                    );
                    enableUpload = true;
                  }
                });
              } else {
                if (
                  element[section.link]?.length &&
                  element.file === undefined &&
                  element[section.path] === ""
                ) {
                  const fileExtension = element[section.link].replace(
                    /^.*\./,
                    ""
                  );
                  formatedUrls.push({
                    name: index + 1 + "_link." + fileExtension,
                    path: element[section.link],
                    index: section.name + index,
                  });
                  enableUpload = true;
                }
                if (element.file !== undefined) {
                  const myNewFile = new File(
                    [element.file],
                    index + 1 + "_" + element.file.name
                  );

                  formData.append(section.name + index, myNewFile);
                  enableUpload = true;
                }
              }

              return null;
            });
          } else {
            if (
              data[section.name][section.link]?.length &&
              data[section.name].file === undefined &&
              data[section.name][section.path] === ""
            ) {
              const fileExtension = data[section.name][section.link].replace(
                /^.*\./,
                ""
              );
              formatedUrls.push({
                name: 1 + "_link." + fileExtension,
                path: data[section.name][section.link],
                index: section.name + 1,
              });
              enableUpload = true;
            }
            if (data[section.name].file !== undefined) {
              const myNewFile = new File(
                [data[section.name].file],
                1 + "_" + data[section.name].file.name
              );
              formData.append(section.name + 1, myNewFile);
              enableUpload = true;
            }
            return null;
          }
        }
      });
      formData.append(
        "urlsList",
        JSON.stringify({
          data: formatedUrls,
        })
      );

      if (enableUpload) {
        crudService
          .post(`/blob-storage-azure/${agencyModel}/${fileId}`, formData)
          .then((s3Response) => {
            sectionName.map((section) => {
              if (data[section.name]) {
                if (
                  typeof data[section.name] !== "object" ||
                  Array.isArray(data[section.name])
                ) {
                  if (section.data) {
                    data[section.name] = data[section.name].map(
                      (oldEle, index) => {
                        let newFormatedObject = {
                          ...oldEle,
                        };

                        section.data.map((dataEle) => {
                          let pathData = s3Response.data.find(
                            (e) =>
                              e.index === section.name + dataEle.path + index
                          );

                          newFormatedObject = {
                            ...newFormatedObject,
                            [dataEle.path]:
                              pathData?.key ?? oldEle[dataEle.path],
                            [dataEle.file]: undefined,
                          };
                        });

                        return newFormatedObject;
                      }
                    );
                  } else {
                    data[section.name] = data[section.name].map(
                      (oldEle, index) => {
                        delete oldEle.file;
                        let pathData = s3Response.data.find(
                          (e) => e.index === section.name + index
                        );

                        let formatedObject = {
                          ...oldEle,
                          [section.path]: pathData?.key ?? oldEle[section.path],
                        };
                        return formatedObject;
                      }
                    );
                  }
                } else {
                  const sectionObject = section.name;
                  const oldEle = data[sectionObject];
                  delete oldEle.file;

                  const pathData = s3Response.data.find(
                    (e) => e.index === sectionObject + 1
                  );

                  const formatedObject = {
                    ...oldEle,
                    [section.path]:
                      pathData?.key ?? data[sectionObject][section.path],
                  };
                  data[sectionObject] = formatedObject;
                }
              }
            });
            crudService
              .put(`/${api}/${fileId}`, {
                ...data,
              })
              .then((res) => {
                dispatch({
                  type: SET_FILE_STATUS,
                  payload: { fileSlected: res.data },
                });
                dispatch({
                  type: EDIT_TYPE,
                  payload: res.data,
                });
              })
              .catch((err) => {
                console.log("error message", err.message);
              });
          });
      }
    }
  };
};

export const searchBoitePresentation = (
  limit = 5,
  offset = 0,
  where = null
) => {
  let queryParams = `limit=${limit}&offset=${offset}`;

  if (where) {
    queryParams += `&filter=${JSON.stringify(where)}`;
  }

  return async (dispatch) => {
    await crudService
      .get(`/boite-presentations?${queryParams}`)
      .then((res) => {
        dispatch({ type: GET_SEARCHED_FILES_BY_COLUMN, payload: res.data });
      })
      .catch((err) => {
        console.log("error message", err.message);
      });
  };
};

export const editEmailAlert = (data, id) => {
  return (dispatch) => {
    dispatch(setLoading());
    crudService
      .patch(`/boite-presentations/alert-mail`, data)
      .then((res) => {
        dispatch({
          type: EDIT_JO,
          payload: data,
        });
      })
      .catch((err) => {
        console.log("error message", err.message);
      })
      .finally(() => {
        dispatch(unsetLoading());
      });
  };
};
