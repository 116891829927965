/* eslint import/no-anonymous-default-export: "off" */

import { GET_JO, ADD_JO, SELECT_JO, EDIT_JO } from "../../Const/ActionTypes";

const initialState = {
  listJO: [],
  joSelected: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_JO: {
      return Object.assign({}, state, {
        listJO: action.payload,
      });
    }
    case ADD_JO: {
      return Object.assign({}, state, {
        listJO: !!action.payload
          ? [action.payload].concat(state.listJO)
          : state.listJO,
      });
    }
    case SELECT_JO: {
      return Object.assign({}, state, {
        joSelected: action.payload,
      });
    }
    case EDIT_JO: {
      let newPrixList = [...state.joSelected.prix_list];
      action.payload.bpIdList.map((e) =>
        state.joSelected.prix_list.map(function (elem, index) {
          if (elem.boitePresentation.bp_id === e.bp_id) {
            newPrixList[index] = {
              ...elem,
              boitePresentation: {
                ...elem.boitePresentation,
                bp_alert_email: e.bp_alert_email,
              },
            };
          }
          return null;
        })
      );
      return Object.assign({}, state, {
        joSelected: {
          ...state.joSelected,
          prix_list: newPrixList,
        },
      });
    }
    default:
      return state;
  }
}
