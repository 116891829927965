// -----------------------Alerte const -----------------------
export const SHOW_ERROR_MESSAGE = "SHOW_ERROR_MESSAGE";
export const HIDE_ERROR_MESSAGE = "HIDE_ERROR_MESSAGE";
export const HIDE_SUCCESS_MESSAGE = "HIDE_SUCCESS_MESSAGE";
export const SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export const SHOW_ERROR_MESSAGE_LIST = "SHOW_ERROR_MESSAGE_LIST";

// -----------------------Auth Module const -----------------------
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const SIGN_OUT_USER = "SIGN_OUT_USER";

// -----------------------Ingredient Module const ----------------------------------------------------
export const GET_ACTIVE_INGREDIENT = "GET_ACTIVE_INGREDIENT";
export const ADD_ACTIVE_INGREDIENT = "ADD_ACTIVE_INGREDIENT";
export const EDIT_ACTIVE_INGREDIENT = "EDIT_ACTIVE_INGREDIENT";
export const DELETE_ACTIVE_INGREDIENT = "DELETE_ACTIVE_INGREDIENT";
export const GET_ACTIVE_INGREDIENT_PAGINATED =
  "GET_ACTIVE_INGREDIENT_PAGINATED";

// ----------------------- Module DCI-----------------------------------------------------------------
export const GET_LIST_DCI = "GET_LIST_DCI";
export const GET_LIST_DCI_PAGINATED = "GET_LIST_DCI_PAGINATED";
export const ADD_DCI = "ADD_DCI";
export const EDIT_DCI = "EDIT_DCI";
export const DELETE_DCI = "DELETE_DCI";
// ----------------------- Module Disease-----------------------------------------------------------------
export const GET_LIST_DISEASE = "GET_LIST_DISEASE";
export const ADD_DISEASE = "ADD_DISEASE";
export const EDIT_DISEASE = "EDIT_DISEASE";
export const DELETE_DISEASE = "DELETE_DISEASE";
export const GET_LIST_DISEASE_PAGINATED = "GET_LIST_DISEASE_PAGINATED";
// ----------------------- Module Speciality Management-----------------------------------------------------------------
export const GET_LIST_SPECIALITY = "GET_LIST_SPECIALITY";
export const ADD_SPECIALITY = "ADD_SPECIALITY";
export const EDIT_SPECIALITY = "EDIT_SPECIALITY";
export const DELETE_SPECIALITY = "DELETE_SPECIALITY";
export const GET_LIST_SPECIALITY_PAGINATED = "GET_LIST_SPECIALITY_PAGINATED";

// ----------------------------- Module Categorie ----------------------------------------------------------------------
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const GET_CATEGORY_LIST_PAGINATED = "GET_CATEGORY_LIST_PAGINATED";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

// ----------------------------- Module Keywords ----------------------------------------------------------------------
export const GET_KEYWORDS_LIST = "GET_KEYWORDS_LIST";
export const ADD_KEYWORDS = "ADD_KEYWORDS";
export const EDIT_KEYWORDS = "EDIT_KEYWORDS";

// ----------------------------- Module Tasks ----------------------------------------------------------------------
export const GET_TASK_LIST = "GET_TASK_LIST";
export const ADD_TASK = "ADD_TASK";
export const EDIT_TASK = "EDIT_TASK";
export const GET_COMMENTS_BY_FILE = "GET_COMMENTS_BY_FILE";
export const DISMISS_TASK = "DISMISS_TASK";

// ----------------------------- Module User ----------------------------------------------------------------------

export const GET_USER_OPTIONS = "GET_USER_OPTIONS";
export const EDIT_USER_OPTIONS = "EDIT_USER_OPTIONS";
export const GET_USER_LIST = "GET_USER_LIST";
export const SUBMIT_USER = "SUBMIT_USER";
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const EDIT_USER_BY_ID = "EDIT_USER_BY_ID";
export const DELETE_USER_BY_ID = "DELETE_USER_BY_ID";

// ----------------------------- Module Fiche TLV ----------------------------------------------------------------------
export const GET_TLV_FILE_LIST = "GET_TLV_FILE_LIST";
export const ADD_FILE_TLV = "ADD_FILE_TLV";
export const EDIT_FILE_TLV = "EDIT_FILE_TLV";
export const DELETE_FILE_TLV = "DELETE_FILE_TLV";

// ----------------------------- Module Fiche CDF ----------------------------------------------------------------------
export const GET_CDF_FILE_LIST = "GET_CDF_FILE_LIST";
export const ADD_FILE_CDF = "ADD_FILE_CDF";
export const EDIT_FILE_CDF = "EDIT_FILE_CDF";
export const DELETE_FILE_CDF = "DELETE_FILE_CDF";

// ----------------------------- Module Zinl ----------------------------------------------------------------------
export const GET_ZINL_FILE_LIST = "GET_ZINL_FILE_LIST";
export const ADD_FILE_ZINL = "ADD_FILE_ZINL";
export const EDIT_FILE_ZINL = "EDIT_FILE_ZINL";
export const DELETE_FILE_ZINL = "DELETE_FILE_ZINL";
// ----------------------------- Module Fiche TLV ----------------------------------------------------------------------
export const GET_NCPE_FILE_LIST = "GET_NCPE_FILE_LIST";
export const ADD_FILE_NCPE = "ADD_FILE_NCPE";
export const EDIT_FILE_NCPE = "EDIT_FILE_NCPE";
export const DELETE_FILE_NCPE = "DELETE_FILE_NCPE";
// ----------------------------- Module Fiche AWSMG ----------------------------------------------------------------------
export const GET_AWMSG_FILE_LIST = "GET_AWMSG_FILE_LIST";
export const ADD_FILE_AWMSG = "ADD_FILE_AWMSG";
export const EDIT_FILE_AWMSG = "EDIT_FILE_AWMSG";
export const DELETE_FILE_AWMSG = "DELETE_FILE_AWMSG";

// ----------------------------- Module Fiche AOTMIT ----------------------------------------------------------------------
export const GET_INAMI_FILE_LIST = "GET_INAMI_FILE_LIST";
export const ADD_FILE_INAMI = "ADD_FILE_INAMI";
export const EDIT_FILE_INAMI = "EDIT_FILE_INAMI";
export const DELETE_FILE_INAMI = "DELETE_FILE_INAMI";

// ----------------------------- Module Fiche EMA ----------------------------------------------------------------------
export const GET_EMA_FILE_LIST = "GET_EMA_FILE_LIST";
export const ADD_FILE_EMA = "ADD_FILE_EMA";
export const EDIT_FILE_EMA = "EDIT_FILE_EMA";
export const DELETE_FILE_EMA = "DELETE_FILE_EMA";

// ----------------------------- Module Fiche Spain ----------------------------------------------------------------------
export const GET_SPAIN_FILE_LIST = "GET_SPAIN_FILE_LIST";
export const ADD_FILE_SPAIN = "ADD_FILE_SPAIN";
export const EDIT_FILE_SPAIN = "EDIT_FILE_SPAIN";
export const DELETE_FILE_SPAIN = "DELETE_FILE_SPAIN";

// ----------------------------- Module Fiche ANSM ----------------------------------------------------------------------
export const GET_ANSM_FILE_LIST = "GET_ANSM_FILE_LIST";
export const ADD_FILE_ANSM = "ADD_FILE_ANSM";
export const EDIT_FILE_ANSM = "EDIT_FILE_ANSM";
export const DELETE_FILE_ANSM = "DELETE_FILE_ANSM";

// ----------------------------- Module Fiche Ntcouncil ----------------------------------------------------------------------
export const GET_NTCOUNCIL_FILE_LIST = "GET_NTCOUNCIL_FILE_LIST";
export const ADD_FILE_NTCOUNCIL = "ADD_FILE_NTCOUNCIL";
export const EDIT_FILE_NTCOUNCIL = "EDIT_FILE_NTCOUNCIL";
export const DELETE_FILE_NTCOUNCIL = "DELETE_FILE_NTCOUNCIL";

// ----------------------------- Module Fiche AOTMIT ----------------------------------------------------------------------
export const GET_AOTMIT_FILE_LIST = "GET_AOTMIT_FILE_LIST";
export const ADD_FILE_AOTMIT = "ADD_FILE_AOTMIT";
export const EDIT_FILE_AOTMIT = "EDIT_FILE_AOTMIT";
export const DELETE_FILE_AOTMIT = "DELETE_FILE_AOTMIT";

// ----------------------------- Module Fiche SMC ----------------------------------------------------------------------
export const GET_SMC_FILE_LIST = "GET_SMC_FILE_LIST";
export const ADD_FILE_SMC = "ADD_FILE_SMC";
export const EDIT_FILE_SMC = "EDIT_FILE_SMC";
export const DELETE_FILE_SMC = "DELETE_FILE_SMC";

// ----------------------------- Module Fiche RTU ----------------------------------------------------------------------
export const GET_RTU_FILE_LIST = "GET_RTU_FILE_LIST";
export const ADD_FILE_RTU = "ADD_FILE_RTU";
export const EDIT_FILE_RTU = "EDIT_FILE_RTU";
export const DELETE_FILE_RTU = "DELETE_FILE_RTU";

// ----------------------------- Module Fiche CEESP ----------------------------------------------------------------------
export const GET_CEESP_FILE_LIST = "GET_CEESP_FILE_LIST";
export const ADD_FILE_CEESP = "ADD_FILE_CEESP";
export const EDIT_FILE_CEESP = "EDIT_FILE_CEESP";
export const DELETE_FILE_CEESP = "DELETE_FILE_CEESP";

// ----------------------------- Module Fiche TFR ----------------------------------------------------------------------
export const GET_TRF_FILE_LIST = "GET_TRF_FILE_LIST";
export const ADD_FILE_TRF = "ADD_FILE_TRF";
export const DELETE_FILE_TRF = "DELETE_FILE_TRF";
export const EDIT_FILE_TFR = "EDIT_FILE_TFR";

// ----------------------------- Module Fiche Nice ----------------------------------------------------------------------
export const GET_NICE_FILE_LIST = "GET_NICE_FILE_LIST";
export const ADD_FILE_NICE = "ADD_FILE_NICE";
export const EDIT_FILE_NICE = "EDIT_FILE_NICE";
export const DELETE_FILE_NICE = "DELETE_FILE_NICE";

// ----------------------------- Module Fiche ICER ----------------------------------------------------------------------
export const GET_ICER_FILE_LIST = "GET_ICER_FILE_LIST";
export const ADD_FILE_ICER = "ADD_FILE_ICER";
export const EDIT_FILE_ICER = "EDIT_FILE_ICER";
export const DELETE_FILE_ICER = "DELETE_FILE_ICER";

// ----------------------------- Module Fiche Boite Presentation ----------------------------------------------------------------------
export const GET_BOITE_PRESENTATION_FILE_LIST =
  "GET_BOITE_PRESENTATION_FILE_LIST";
export const ADD_FILE_BOITE_PRESENTATION = "ADD_FILE_BOITE_PRESENTATION";
export const EDIT_FILE_BOITE_PRESENTATION = "EDIT_FILE_BOITE_PRESENTATION";
export const DELETE_FILE_BOITE_PRESENTATION = "DELETE_FILE_BOITE_PRESENTATION";

// ----------------------------- Module Fiche Italy ----------------------------------------------------------------------
export const GET_ITALY_FILE_LIST = "GET_ITALY_FILE_LIST";
export const ADD_FILE_ITALY = "ADD_FILE_ITALY";
export const EDIT_FILE_ITALY = "EDIT_FILE_ITALY";
export const DELETE_FILE_ITALY = "DELETE_FILE_ITALY";

// ----------------------------- Module Fiche PBAC ----------------------------------------------------------------------
export const ADD_FILE_PBAC = "ADD_FILE_PBAC";
export const EDIT_FILE_PBAC = "EDIT_FILE_PBAC";
export const GET_PBAC_FILE_LIST = "GET_PBAC_FILE_LIST";
export const DELETE_FILE_PBAC = "DELETE_FILE_PBAC";

// ----------------------------- Module Fiche ACE ----------------------------------------------------------------------
export const ADD_FILE_ACE = "ADD_FILE_ACE";
export const EDIT_FILE_ACE = "EDIT_FILE_ACE";
export const GET_ACE_FILE_LIST = "GET_ACE_FILE_LIST";
export const DELETE_FILE_ACE = "DELETE_FILE_ACE";

// ----------------------------- Module Fiche PMDA ----------------------------------------------------------------------
export const ADD_FILE_PMDA = "ADD_FILE_PMDA";
export const EDIT_FILE_PMDA = "EDIT_FILE_PMDA";
export const GET_PMDA_FILE_LIST = "GET_PMDA_FILE_LIST";
export const DELETE_FILE_PMDA = "DELETE_FILE_PMDA";

// ----------------------------- Module Fiche HIRA ----------------------------------------------------------------------
export const ADD_FILE_HIRA = "ADD_FILE_HIRA";
export const EDIT_FILE_HIRA = "EDIT_FILE_HIRA";
export const GET_HIRA_FILE_LIST = "GET_HIRA_FILE_LIST";
export const DELETE_FILE_HIRA = "DELETE_FILE_HIRA";

// ----------------------------- Module Fiche Nihta ----------------------------------------------------------------------
export const ADD_FILE_NIHTA = "ADD_FILE_NIHTA";
export const EDIT_FILE_NIHTA = "EDIT_FILE_NIHTA";
export const GET_NIHTA_FILE_LIST = "GET_NIHTA_FILE_LIST";
export const DELETE_FILE_NIHTA = "DELETE_FILE_NIHTA";

// ----------------------------- Module Fiche FDA ----------------------------------------------------------------------
export const ADD_FILE_FDA = "ADD_FILE_FDA";
export const EDIT_FILE_FDA = "EDIT_FILE_FDA";
export const GET_FDA_FILE_LIST = "GET_FDA_FILE_LIST";
export const DELETE_FILE_FDA = "DELETE_FILE_FDA";
// ----------------------------- Module Fiche BIOSIMILAIRE ----------------------------------------------------------------------
export const GET_FILE_BIOSIMILAIRE = "GET_FILE_BIOSIMILAIRE";
export const ADD_FILE_BIOSIMILAIRE = "ADD_FILE_BIOSIMILAIRE";
export const EDIT_FILE_BIOSIMILAIRE = "EDIT_FILE_BIOSIMILAIRE";
export const DELETE_FILE_BIOSIMILAIRE = "DELETE_FILE_BIOSIMILAIRE";
// ----------------------------- Module Fiche AWSMG ----------------------------------------------------------------------
export const GET_INDICATION_JO_FILE_LIST = "GET_INDICATION_JO_FILE_LIST";
export const ADD_FILE_INDICATION_JO = "ADD_FILE_INDICATION_JO";
export const EDIT_FILE_INDICATION_JO = "EDIT_FILE_INDICATION_JO";
export const DELETE_FILE_INDICATION_JO = "DELETE_FILE_INDICATION_JO";
// ----------------------------- Module Fiche ECONOMIC EVALUATION ----------------------------------------------------------------------
export const GET_ECONOMIC_EVALUATION_FILE_LIST =
  "GET_ECONOMIC_EVALUATION_FILE_LIST";
export const ADD_FILE_ECONOMIC_EVALUATION = "ADD_FILE_ECONOMIC_EVALUATION";
export const EDIT_FILE_ECONOMIC_EVALUATION = "EDIT_FILE_ECONOMIC_EVALUATION";
export const DELETE_FILE_ECONOMIC_EVALUATION =
  "DELETE_FILE_ECONOMIC_EVALUATION";
export const SET_TYPE_OF_ECO_ANALY_FILE_ECONOMIC_EVALUATION =
  "SET_TYPE_OF_ECO_ANALY_FILE_ECONOMIC_EVALUATION";

// ----------------------------- Module Fiche Trancparecny ----------------------------------------------------------------------
export const GET_TRANSPARENCY_FILE_LIST = "GET_TRANSPARENCY_FILE_LIST";
export const ADD_TRANSPARENCY_FILE_LIST = "ADD_TRANSPARENCY_FILE_LIST";
export const EDIT_TRANSPARENCY_FILE_LIST = "EDIT_TRANSPARENCY_FILE_LIST";
export const DELETE_TRANSPARENCY_FILE_LIST = "DELETE_TRANSPARENCY_FILE_LIST";

// ----------------------------- Module Fiche APAC ----------------------------------------------------------------------
export const GET_APAC_FILE_LIST = "GET_APAC_FILE_LIST";
export const ADD_FILE_APAC = "ADD_FILE_APAC";
export const EDIT_FILE_APAC = "EDIT_FILE_APAC";
export const DELETE_FILE_APAC = "DELETE_FILE_APAC";

// ----------------------------- Module Fiche Prix Germany ----------------------------------------------------------------------
export const GET_PRICING_GERMANY_FILE_LIST = "GET_PRICING_GERMANY_FILE_LIST";
export const ADD_FILE_PRICING_GERMANY = "ADD_FILE_PRICING_GERMANY";
export const EDIT_FILE_PRICING_GERMANY = "EDIT_FILE_PRICING_GERMANY";
export const DELETE_FILE_PRICING_GERMANY = "DELETE_FILE_PRICING_GERMANY";

// ----------------------------- Module Fiche Prix Spain ----------------------------------------------------------------------
export const GET_PRICING_SPAIN_FILE_LIST = "GET_PRICING_SPAIN_FILE_LIST";
export const ADD_FILE_PRICING_SPAIN = "ADD_FILE_PRICING_SPAIN";
export const EDIT_FILE_PRICING_SPAIN = "EDIT_FILE_PRICING_SPAIN";
export const DELETE_FILE_PRICING_SPAIN = "DELETE_FILE_PRICING_SPAIN";

// ----------------------------- Module Fiche Prix Korea ----------------------------------------------------------------------

export const GET_PRICING_KOREA_FILE_LIST = "GET_PRICING_KOREA_FILE_LIST";
export const ADD_FILE_PRICING_KOREA = "ADD_FILE_PRICING_KOREA";
export const EDIT_FILE_PRICING_KOREA = "EDIT_FILE_PRICING_KOREA";
export const DELETE_FILE_PRICING_KOREA = "DELETE_FILE_PRICING_KOREA";

// ----------------------------- Module Fiche NHSA ----------------------------------------------------------------------
export const ADD_FILE_NHSA = "ADD_FILE_NHSA";
export const EDIT_FILE_NHSA = "EDIT_FILE_NHSA";
export const GET_NHSA_FILE_LIST = "GET_NHSA_FILE_LIST";
export const DELETE_FILE_NHSA = "DELETE_FILE_NHSA";

// ----------------------------- Module Fiche Prix Japan ----------------------------------------------------------------------
export const GET_PRICING_JAPAN_FILE_LIST = "GET_PRICING_JAPAN_FILE_LIST";
export const ADD_FILE_PRICING_JAPAN = "ADD_FILE_PRICING_JAPAN";
export const EDIT_FILE_PRICING_JAPAN = "EDIT_FILE_PRICING_JAPAN";
export const DELETE_FILE_PRICING_JAPAN = "DELETE_FILE_PRICING_JAPAN";

// ----------------------------- Module Fiche NMPA ----------------------------------------------------------------------
export const ADD_FILE_NMPA = "ADD_FILE_NMPA";
export const EDIT_FILE_NMPA = "EDIT_FILE_NMPA";
export const GET_NMPA_FILE_LIST = "GET_NMPA_FILE_LIST";
export const DELETE_FILE_NMPA = "DELETE_FILE_NMPA";

// ----------------------------- Module Fiche PRICING_SWEDEN ----------------------------------------------------------------------
export const ADD_FILE_PRICING_SWEDEN = "ADD_FILE_PRICING_SWEDEN";
export const EDIT_FILE_PRICING_SWEDEN = "EDIT_FILE_PRICING_SWEDEN";
export const GET_PRICING_SWEDEN_FILE_LIST = "GET_PRICING_SWEDEN_FILE_LIST";
export const DELETE_FILE_PRICING_SWEDEN = "DELETE_FILE_PRICING_SWEDEN";
// ----------------------------- Module Fiche Prix ITALY----------------------------------------------------------------------
export const GET_PRICING_ITALY_FILE_LIST = "GET_PRICING_ITALY_FILE_LIST";
export const ADD_FILE_PRICING_ITALY = "ADD_FILE_PRICING_ITALY";
export const EDIT_FILE_PRICING_ITALY = "EDIT_FILE_PRICING_ITALY";
export const DELETE_FILE_PRICING_ITALY = "DELETE_FILE_PRICING_ITALY";

// ----------------------------- Module Fiche PRICING_Australie----------------------------------------------------------------------
export const ADD_FILE_PRICING_AUSTRALIE = "ADD_FILE_PRICING_AUSTRALIE";
export const EDIT_FILE_PRICING_AUSTRALIE = "EDIT_FILE_PRICING_AUSTRALIE";
export const GET_PRICING_AUSTRALIE_FILE_LIST =
  "GET_PRICING_AUSTRALIE_FILE_LIST";
export const DELETE_FILE_PRICING_AUSTRALIE = "DELETE_FILE_PRICING_AUSTRALIE";

// ----------------------------- Module Gestion JO----------------------------------------------------------------------
export const GET_JO = "GET_JO";
export const ADD_JO = "ADD_JO";
export const EDIT_JO = "EDIT_JO";
export const SELECT_JO = "SELECT_JO";
// ----------------------------- Module Fiche BRESIL ----------------------------------------------------------------------
export const ADD_FILE_PRICING_BRESIL = "ADD_FILE_PRICING_BRESIL";
export const EDIT_FILE_PRICING_BRESIL = "EDIT_FILE_PRICING_BRESIL";
export const GET_PRICING_BRESIL_FILE_LIST = "GET_PRICING_BRESIL_FILE_LIST";
export const DELETE_FILE_PRICING_BRESIL = "DELETE_FILE_PRICING_BRESIL";

// ----------------------------- Module Fiche INAMI ----------------------------------------------------------------------
export const ADD_FILE_PRICING_INAMI = "ADD_FILE_PRICING_INAMI";
export const EDIT_FILE_PRICING_INAMI = "EDIT_FILE_PRICING_INAMI";
export const GET_PRICING_INAMI_FILE_LIST = "GET_PRICING_INAMI_FILE_LIST";
export const DELETE_FILE_PRICING_INAMI = "DELETE_FILE_PRICING_INAMI";

// ----------------------------- Module Fiche IQWIG ----------------------------------------------------------------------
export const GET_IQWIG_FILE_LIST = "GET_IQWIG_FILE_LIST";
export const ADD_FILE_IQWIG = "ADD_FILE_IQWIG";
export const EDIT_FILE_IQWIG = "EDIT_FILE_IQWIG";

export const DELETE_FILE_IQWIG = "DELETE_FILE_IQWIG";

// ----------------------------- Module Fiche Clinical Trials ----------------------------------------------------------------------
export const GET_CLINICATL_TRIALS_FILE_LIST = "GET_CLINICATL_TRIALS_FILE_LIST";
export const ADD_FILE_CLINICAL_TRIAL = "ADD_FILE_CLINICAL_TRIAL";
export const EDIT_FILE_CLINICAL_TRIAL = "EDIT_FILE_CLINICAL_TRIAL";
export const DELETE_FILE_CLINICAL_TRIAL = "DELETE_FILE_CLINICAL_TRIAL";
export const VERIFY_NCT = "VERIFY_NCT";

// ----------------------------- Module Studies ----------------------------------------------------------------------
export const GET_STUDIES = "GET_STUDIES";
export const ADD_STUDIES = "ADD_STUDIES";
export const EDIT_STUDIES = "EDIT_STUDIES";
export const DELETE_STUDIES = "DELETE_STUDIES";

// ----------------------------- Module Fiche GBA ----------------------------------------------------------------------
export const GET_GBA_FILE_LIST = "GET_GBA_FILE_LIST";
export const ADD_FILE_GBA = "ADD_FILE_GBA";
export const EDIT_FILE_GBA = "EDIT_FILE_GBA";
export const DELETE_FILE_GBA = "DELETE_FILE_GBA";

// ----------------------------- Module Fiche PERU----------------------------------------------------------------------
export const GET_PERU_FILE_LIST = "GET_PERU_FILE_LIST";
export const ADD_FILE_PERU = "ADD_FILE_PERU";
export const EDIT_FILE_PERU = "EDIT_FILE_PERU";
export const DELETE_FILE_PERU = "DELETE_FILE_PERU";

// ----------------------------- Module Fiche pCODR (CA) ----------------------------------------------------------------------
export const GET_pCODR_FILE_LIST = "GET_pCODR_FILE_LIST";
export const ADD_FILE_pCODR = "ADD_FILE_pCODR";
export const EDIT_FILE_pCODR = "EDIT_FILE_pCODR";
export const DELETE_FILE_pCODR = "DELETE_FILE_pCODR";
// ----------------------------- Module Fiche CADTH ----------------------------------------------------------------------
export const GET_CADTH_FILE_LIST = "GET_CADTH_FILE_LIST";
export const ADD_FILE_CADTH = "ADD_FILE_CADTH";
export const EDIT_FILE_CADTH = "EDIT_FILE_CADTH";
export const DELETE_FILE_CADTH = "DELETE_FILE_CADTH";
// ----------------------------- Module Fiche Bresil ----------------------------------------------------------------------
export const GET_BRESIL_FILE_LIST = "GET_BRESIL_FILE_LIST";
export const ADD_FILE_BRESIL = "ADD_FILE_BRESIL";
export const EDIT_FILE_BRESIL = "EDIT_FILE_BRESIL";
export const DELETE_FILE_BRESIL = "DELETE_FILE_BRESIL";

// ----------------------------- File Settings ----------------------------------------------------------------------
export const SET_FILE_STATUS = "SET_FILE_STATUS";
export const SET_FILE_SECTIONS = "SET_FILE_SECTIONS";
export const SET_FILE_HEADERS = "SET_FILE_HEADERS";
export const SET_FILE_MODAL_HEADERS = "SET_FILE_MODAL_HEADERS";
export const SET_ALL_FILE_HEADERS = "SET_ALL_FILE_HEADERS";
export const SEELECT_FILE_MODAL_HEADERS = "SEELECT_FILE_MODAL_HEADERS";
export const SEELECT_ALL_FILE_MODAL_HEADERS = "SEELECT_ALL_FILE_MODAL_HEADERS";
export const SET_DUPLICATION_ID = "SET_DUPLICATION_ID";
export const INCREMENT_COUNT = "INCREMENT_COUNT";
export const DECREMENT_COUNT = "DECREMENT_COUNT";
export const SET_MODAL_FILE_STATUS = "SET_MODAL_FILE_STATUS";
export const CLEAR_MODAL_FILE_STATUS = "CLEAR_MODAL_FILE_STATUS";
export const GET_SEARCHED_FILES_BY_COLUMN = "GET_SEARCHED_FILES_BY_COLUMN";
export const SET_SEARCH_FILE_STATUS = "SET_SEARCH_FILE_STATUS";
export const CLEAR_SEARCH_FILE_STATUS = "CLEAR_SEARCH_FILE_STATUS";
export const COUNT_SEARCH = "COUNT_SEARCH";
export const STORE_SELECTED_ITEM = "STORE_SELECTED_ITEM";
export const STORE_STATE = "STORE_STATE";
export const SET_SORTED_FILE_LIST = "SET_SORTED_FILE_LIST";
export const SET_SEARCH_FILTER_ARGS = "SET_SEARCH_FILTER_ARGS";
export const UPDATE_SEARCHED_FILES = "UPDATE_SEARCHED_FILES";




// ----------------------------- HTARODMAP ----------------------------------------------------------------------
export const GET_LIST_HTAROADMAP = "GET_LIST_HTAROADMAP";
export const ADD_HTAROADMAP = "ADD_HTAROADMAP";
export const EDIT_HTAROADMAP = "EDIT_HTAROADMAP";
export const DELETE_FILE_HTAROADMAP = "DELETE_FILE_HTAROADMAP";

// ----------------------------- Module Fiche Agency  ----------------------------------------------------------------------
export const GET_AGENCY_FILE_LIST = "GET_AGENCY_FILE_LIST";
export const ADD_FILE_AGENCY = "ADD_FILE_AGENCY";
export const EDIT_FILE_AGENCY = "EDIT_FILE_AGENCY";
export const DELETE_FILE_AGENCY = "DELETE_FILE_AGENCY";
export const GET_COUNTRIES = "GET_COUNTRIES";

// ----------------------------- UI ----------------------------------------------------------------------
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_FIND_BY_ID = "SET_LOADING_FIND_BY_ID";
export const UNSET_LOADING = "UNSET_LOADING";
export const UNSET_LOADING_FIND_BY_ID = "UNSET_LOADING_FIND_BY_ID";
export const LOADING_GET_PROFILE = "LOADING_GET_PROFILE";
export const LOADING_LOG_OUT = "LOADING_LOG_OUT";
export const LOADING_SEARCH_FILE = "LOADING_SEARCH_FILE";
export const LOADING_SELECT_INPUTS = "LOADING_SELECT_INPUTS";

// ----------------------- SMR List-----------------------------------------------------------------
export const GET_LIST_SMR = "GET_LIST_SMR";
export const GET_LIST_ASMR = "GET_LIST_ASMR";
//--------------------PATIENT POPULATION LIST------------------/
export const GET_LIST_PATIENT_POPULATION = "GET_LIST_PATIENT_POPULATION";
//--------------------iqwig-extent-probabilitie LIST------------------/
export const GET_LIST_IQWIG_EXTENT_PROBABILITIE =
  "GET_LIST_IQWIG_EXTENT_PROBABILITIE";
